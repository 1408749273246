<template>
  <div class="d-flex flex-column">
    <recipient-picker
      class=" mt-4"
      :document.sync="computedRecipients"
    />
    <v-text-field
      v-model="computedSubject"
      :rules="rules"
      :label="$t('t.Subject')"
      :dense="dense"
      counter="255"
    />
    <v-text-field
      v-model="computedBody"
      :rules="rules"
      :label="$t('t.Content')"
      :dense="dense"
      counter="255"
    />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  components: {
    RecipientPicker: () => import('./recipient-picker')
  },
  computed: {
    computedRecipients: {
      get () { return this.dataRecipients },
      set (v) {
        this.dataRecipients = v
        this.dataDocumentEmit()
      }
    },
    computedSubject: {
      get () { return this.dataSubject },
      set (v) {
        this.dataSubject = v
        this.dataDocumentEmit()
      }
    },
    computedBody: {
      get () { return this.dataBody },
      set (v) {
        this.dataBody = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    const required = [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')]
    const data = {
      dataBody: null,
      dataSubject: null,
      dataRecipients: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      rules: required.concat(this.sizeRules(250))
    }
    return data
  },
  methods: {
    emitDocument () {
      const doc = {
        type: 'send-e-mail',
        recipients: this.dataRecipients,
        subject: this.dataSubject,
        body: this.dataBody
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    sizeRules (size) {
      return [v => (v?.length || 0) <= size || this.$t('t.MaxLength', { value: size })]
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataRecipients = v ? this.lodash.cloneDeep(v?.recipients) : []
        this.dataSubject = v?.subject
        this.dataBody = v?.body
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
